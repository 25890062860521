import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_BaseInput = _resolveComponent("BaseInput")
  const _component_BaseButton = _resolveComponent("BaseButton")
  const _component_Form = _resolveComponent("Form")

  return (_openBlock(), _createBlock(_component_Form, {
    onSubmit: _cache[2] || (_cache[2] = ($event: any) => (_ctx.$emit('submit'))),
    "validation-schema": _ctx.validationSchema
  }, {
    default: _withCtx(() => [
      _createVNode(_component_BaseInput, {
        modelValue: _ctx.localEmail,
        "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => (_ctx.localEmail = $event)),
        class: "mt-4",
        name: "email",
        label: _ctx.$t('forms.reset-request.email.label'),
        placeholder: _ctx.$t('forms.reset-request.email.placeholder'),
        "input-classes": "w-full h-11"
      }, null, 8, ["modelValue", "label", "placeholder"]),
      _createVNode(_component_BaseButton, {
        type: "submit",
        class: "mt-5 px-14",
        size: "lg",
        loading: _ctx.loading
      }, {
        default: _withCtx(() => [
          _createTextVNode(_toDisplayString(_ctx.$t('actions.reset')), 1)
        ]),
        _: 1
      }, 8, ["loading"])
    ]),
    _: 1
  }, 8, ["validation-schema"]))
}