
import { defineComponent, computed, WritableComputedRef } from 'vue';
import { Form } from 'vee-validate';
import BaseInput from '@/components/base/BaseInput.vue';
import BaseButton from '@/components/base/BaseButton.vue';

const ResetPasswordRequestForm = defineComponent({
  components: { BaseInput, BaseButton, Form },

  emits: ['update:modelValue', 'submit'],

  props: {
    modelValue: {
      type: String,
      required: true,
    },
    loading: Boolean,
  },

  setup(props, { emit }) {
    const validationSchema = {
      email: 'required|email',
    };

    const localEmail: WritableComputedRef<string> = computed({
      get: (): string => props.modelValue,
      set: (value: string): void => emit('update:modelValue', value),
    });

    return { localEmail, validationSchema };
  },
});

export default ResetPasswordRequestForm;
