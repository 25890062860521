
import { computed, defineComponent, ref } from 'vue';
import BaseCard from '@/components/base/BaseCard';
import ResetPasswordRequestForm from '@/components/forms/ResetPasswordRequestForm.vue';
import BaseAlertMessage from '@/components/base/BaseAlertMessage.vue';
import BaseInformation from '@/components/base/BaseInformation.vue';
import BaseButton from '@/components/base/BaseButton.vue';
import useAuth from '@/mixins/useAuth';
import RequestStatus from '@/constants/RequestStatus';
import * as Sentry from '@sentry/vue';

const TheResetPasswodRequest = defineComponent({
  components: {
    BaseCard,
    ResetPasswordRequestForm,
    BaseAlertMessage,
    BaseInformation,
    BaseButton,
  },

  setup() {
    const { requestPasswordRecovery } = useAuth();
    const isRequestSent = ref<boolean>(false);

    const email = ref<string>('');
    const status = ref<RequestStatus>(RequestStatus.Initial);

    const isLoading = computed(() => status.value === RequestStatus.Loading);
    const isError = computed(() => status.value === RequestStatus.Error);

    const onSubmit = async (): Promise<void> => {
      status.value = RequestStatus.Loading;

      const response = await requestPasswordRecovery(email.value);

      if (!response) {
        status.value = RequestStatus.Error;
        Sentry.captureException(new Error('Can\'t request password reset'));

        return;
      }

      status.value = RequestStatus.Success;
      isRequestSent.value = true;
    };

    return {
      isLoading,
      isError,
      isRequestSent,
      email,
      onSubmit,
    };
  },
});

export default TheResetPasswodRequest;
